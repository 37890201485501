import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true,
      isPreload: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) this.isLoading = true;
      },
      immediate: true,
      deep: true
    },
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
        if (newPath === "/") {
          this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
          this.WebApp.isClosingConfirmationEnabled = true;
          return;
        }
        this.WebApp.BackButton.show();
      },
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "loadTaskCount"]),
    ...userVuex.mapActions(["login", "getUserData", "powerPlus"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    async perloadResultFn() {
      this.isPreload = false;
      await this.initQuest();
    },
    initStatistics() {
      document.title = this.setting.coin;
      if (this.setting.statistics.length > 0) {
        this.setting.statistics.map(item => {
          if (item.type == "plausible" && item.value) {
            const pScript = document.createElement("script");
            pScript.src = `https://plausible.io/js/script.js`;
            pScript.defer = true;
            pScript.setAttribute("data-domain", item.value);
            document.head.appendChild(pScript);
          }
          if (item.type == "google" && item.value) {
            const gaScript = document.createElement("script");
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${item.value}`;
            gaScript.async = true;
            gaScript.onload = () => {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", item.value);
            };
            document.head.appendChild(gaScript);
          }
        });
      }
    },
    onlineRefresh() {
      if (this.userData) this.$http.post("/user/online/refresh", {});
      setTimeout(() => {
        this.onlineRefresh();
      }, 180 * 1000);
    },
    async initQuest() {
      // this.powerPlus();
      // this.initStatistics();

      // setTimeout(() => {
      //   console.log("refresh");
      //   this.onlineRefresh();
      // }, 180 * 1000);
      this.WebApp.BackButton.onClick(() => {
        this.$router.go(-1);
      });
      this.WebApp.isClosingConfirmationEnabled = true;
      this.WebApp.disableVerticalSwipes();
    }
  },
  async mounted() {
    await this.getSetting();
    this.initStatistics();
    if (this.theme === "rocket") return;
    await this.login([this.WebApp.initData, this.WebApp.initDataUnsafe.start_param || ""]);
    this.powerPlus();
    setTimeout(() => {
      console.log("refresh");
      this.onlineRefresh();
    }, 180 * 1000);
    this.WebApp.BackButton.onClick(() => {
      this.$router.go(-1);
    });
    this.WebApp.isClosingConfirmationEnabled = true;
    this.WebApp.disableVerticalSwipes();
  }
};