import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mMenu",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {};
  },
  methods: {
    onSkip(page, id) {
      console.log(8888);
      if (page == "squad") {
        this.$router.push(`/${page}?id=${id}`);
        return;
      }
      this.$router.push(`/${page}`);
    }
  },
  mounted() {
    //this.loadTaskCount();
  }
};