var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pageMine"
  }, [_c('div', {
    staticClass: "pageMine-main",
    attrs: {
      "id": "mine"
    }
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-balance-ad wow fadeInRight",
    style: {
      background: `url(${require(`@images/${_vm.theme}/mine-head-ad.jpg`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "head-balance wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "head-balance__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("nFormatter")(_vm.mineBalance, 2)))]), !isNaN(_vm.earnings.hour_earnings) ? _c('div', {
    staticClass: "head-balance__title"
  }, [_vm._v(" Profit per hour"), _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toThousands")(_vm.earnings.hour_earnings)))])]) : _vm._e()])]), _c('div', {
    staticClass: "bounty"
  }, [_c('div', {
    staticClass: "bounty-head"
  }, [_c('div', {
    staticClass: "bounty-head__title",
    on: {
      "click": _vm.onAwardTip
    }
  }, [_vm._v(_vm._s(_vm.setting.coin) + " Bounty "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/mark.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "bounty-btns"
  }, [_vm.lastTimeStr > 0 ? _c('div', {
    staticClass: "bounty-time"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/clock.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.lastTimeStr))]) : _vm._e(), _c('div', {
    staticClass: "bounty-btn"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(_vm.specialsMineInfo.coin, 2)) + "/")]), _vm._v(_vm._s(_vm._f("nFormatter")(_vm.specialsMineInfo.totalCoin, 2)) + " ")])])]), _vm.isBountyListLoading ? [_c('div', {
    staticClass: "bounty-load"
  }, [_c('div', {
    staticClass: "sLoading"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0)])] : _vm.specialsMineInfo.list.length > 0 ? [_c('transition-group', {
    staticClass: "bounty-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterMineBountyAnim
    }
  }, _vm._l(_vm.specialsMineInfo.list, function (item, index) {
    return _c('div', {
      key: `o${index}`,
      staticClass: "bounty-item",
      class: item && item.opened == 1 && 'active',
      attrs: {
        "data-index": index + 10
      },
      on: {
        "click": function ($event) {
          return _vm.openSpecialsDetail(item);
        }
      }
    }, [item.opened == 0 || item.gray ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/mine-unknow.png`),
        "alt": ""
      }
    }) : _vm._e(), item && item.opened == 1 ? [_c('img', {
      attrs: {
        "src": item.lucky_task_icon,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "specials__name"
    }, [_vm._v(" " + _vm._s(item.lucky_task_short_name) + " ")]), _c('div', {
      staticClass: "bounty__name"
    }, [_c('span', [_vm._v("Hint")])])] : _vm._e()], 2);
  }), 0)] : _vm._e()], 2), _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll"
    }],
    staticClass: "tab"
  }, _vm._l(_vm.categoryList, function (item, index) {
    return _c('div', {
      staticClass: "tab-item",
      class: _vm.selectTab == item.id ? 'checked' : '',
      on: {
        "click": function ($event) {
          !_vm.isMobile && _vm.click_tab(item.id);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.click_tab(item.id);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0), [_c('div', {
    staticClass: "list"
  }, [_vm.isLoading ? [_c('div', {
    staticClass: "sLoading"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0)] : [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.taskList[_vm.selectTab] ? _vm._l(_vm.taskList[_vm.selectTab], function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "item",
      class: item.pre_task_id != 'null' && !item.upgradable ? 'lock' : '',
      attrs: {
        "data-index": index
      },
      on: {
        "click": function ($event) {
          return _vm.onBoo(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-main"
    }, [_c('div', {
      staticClass: "item-img"
    }, [_c('div', {
      staticClass: "item-img-main"
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item-lock"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/lock.png`),
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__title"
    }, [_vm._v(_vm._s(item.short_name))]), _c('div', {
      staticClass: "item-ele"
    }, [_c('div', {
      staticClass: "item__pro"
    }, [_vm._v(_vm._s(_vm.$lang("Profit per hour")))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), item.current_level == 0 ? _c('span', [_vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.initial_earnings, 2)) + " ")]) : _c('span', [item.current_earnings > 0 ? [_vm._v(" " + _vm._s(_vm._f("nFormatter")(item.current_earnings, 2)) + " ")] : [_vm._v("--")]], 2)]), _c('div', {
      staticClass: "item__level"
    }, [_vm._v("Level " + _vm._s(item.current_level))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(item.upgrade_cost, 2)) + " ")])])])])]);
  }) : _vm._e()], 2)]], 2)]], 2), _c('pop-mine-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "type": _vm.selectCategory.icon_type,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  }), _c('pop-tip-energy', {
    attrs: {
      "show": _vm.popEngrayTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEngrayTipShow = $event;
      }
    }
  }), _c('pop-award-tip', {
    attrs: {
      "show": _vm.popAwardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardTipShow = $event;
      }
    }
  }), _c('pop-award', {
    attrs: {
      "show": _vm.popAwardShow,
      "award": _vm.awardCoin
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardShow = $event;
      }
    }
  }), _c('pop-special-detail', {
    attrs: {
      "show": _vm.popSpecialsDetailShow,
      "row": _vm.specialsItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popSpecialsDetailShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };