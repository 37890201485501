var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "menu",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "menu-bg"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/menu-bg.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-main"
  }, [_vm.theme == 'tapcoin' ? [_c('div', {
    staticClass: "menu-item",
    class: (_vm.route == '' || _vm.route == '/') && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-home.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("TAP")))])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/earn' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('earn');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('earn');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-earn.png`),
      "alt": ""
    }
  }), _vm.taskCount ? _c('span', [_vm._v(_vm._s(_vm.taskCount))]) : _vm._e()]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("TASKS")))])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/mine' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('mine');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('mine');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-mine.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("STUDY")))])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/activity' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('activity');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('activity');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-activity.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("EARN")))])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/airdrop' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('airdrop');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('airdrop');
      }
    }
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-airdrop.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "min"
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("AIRDROP")))])])] : _vm.theme == 'rocket' ? [_c('div', {
    staticClass: "menu-item",
    class: (_vm.route == '' || _vm.route == '/') && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-home${`${_vm.route == '' || (_vm.route == '/' ? '-active' : '')}`}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Home")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/earn' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('earn');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('earn');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-earn${`${_vm.route == '/earn' ? '-active' : ''}`}.png`),
      "alt": ""
    }
  }), _vm.taskCount ? _c('span', [_vm._v(_vm._s(_vm.taskCount))]) : _vm._e()]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Earn")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/mine' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('mine');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('mine');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-mine${`${_vm.route == '/mine' ? '-active' : ''}`}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Mine")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/rank' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('rank');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('rank');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-ranking${`${_vm.route == '/rank' ? '-active' : ''}`}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Rank")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/frens' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.onSkip('frens');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('frens');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-frens${`${_vm.route == '/frens' ? '-active' : ''}`}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Frens")))])])])] : [_c('div', {
    staticClass: "menu-item",
    class: (_vm.route == '' || _vm.route == '/') && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-home.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Home")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/earn' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('earn');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('earn');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-earn.png`),
      "alt": ""
    }
  }), _vm.taskCount ? _c('span', [_vm._v(_vm._s(_vm.taskCount))]) : _vm._e()]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Earn")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/mine' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('mine');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('mine');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-mine.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Mine")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/rank' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('rank');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('rank');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-ranking.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Rank")))])])]), _c('div', {
    staticClass: "menu-item",
    class: _vm.route == '/frens' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('frens');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('frens');
      }
    }
  }, [_c('div', {
    staticClass: "menu-item-main"
  }, [_c('div', {
    staticClass: "menu-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-btn-frens.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu-label"
  }, [_vm._v(_vm._s(_vm.$lang("Frens")))])])])]], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };