var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "reflectionIndex",
    class: [_vm.isMobile && 'wap', _vm.theme]
  }, [void 0, _c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top-main"
  }, [_c('div', {
    staticClass: "header wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-squad.png`)})`
    }
  }), _c('span', [_vm.userData.user_group_id ? [_vm._v(" " + _vm._s(_vm.userData.user_group_name) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("JOIN SQUAD")) + " ")]], 2)])]), _c('div', {
    ref: "totalRef",
    staticClass: "total",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
    }
  }), _c('div', {
    staticClass: "total__value",
    class: !_vm.isMobile && 'pc'
  }, [!_vm.isIos ? [_c('CountUp', {
    attrs: {
      "num": _vm.userData.coin
    }
  })] : [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))]], 2)])]), _c('div', {
    staticClass: "center"
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "center-main",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleDownClick($event);
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.handleUpClick($event);
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.handleDownClick($event);
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.handleUpClick($event);
      }
    }
  }, [_vm._m(0)])]), _c('div', {
    ref: "coinListRef",
    staticClass: "coin_list"
  }), _c('div', {
    staticClass: "bottomX",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "bottomX-main"
  }, [_c('div', {
    staticClass: "progress wow fadeInLeft",
    style: {
      background: `url(${require(`@images/${_vm.theme}/progress-bg.png`)}) center/100% auto no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "bar"
  }, [_c('div', {
    staticClass: "bar-main",
    style: {
      height: Number(_vm.progressBar) + '%'
    }
  })]), _c('div', {
    staticClass: "tag",
    style: {
      background: `url(${require(`@images/${_vm.theme}/sd.png`)}) center/100% auto no-repeat`
    }
  }), _c('div', {
    staticClass: "progress__text"
  }, [_c('span', [_vm._v(_vm._s(_vm.userData.power))])])])]), _c('div', {
    staticClass: "btn-boosts wow fadeInRight",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/btn-boost-bg.png`)})`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('boost');
      }
    }
  }, [_c('div', {
    staticClass: "btn-boosts-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/btn-icon-boost.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Boosts")) + " ")])])]), _c('m-menu')], 1), [_c('div', {
    ref: "blockRef",
    staticClass: "block",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleDownClick($event);
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.handleUpClick($event);
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.handleDownClick($event);
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.handleUpClick($event);
      }
    }
  }, _vm._l(_vm.blockAnimList, function (item, index) {
    return _c('span', {
      key: index,
      class: item.bool && 'active',
      style: {
        left: item.x,
        top: item.y
      }
    }, [_vm._v("+" + _vm._s(_vm.turbo_temp_times <= 0 ? _vm.userData.multi_tap : _vm.userData.multi_tap * _vm.turbo_temp_times))]);
  }), 0)], _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })]), _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  }), _c('pop-login-reward', {
    attrs: {
      "show": _vm.popLoginRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLoginRewardShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "canvas"
  }, [_c('canvas', {
    attrs: {
      "id": "rocketCanvas"
    }
  })]);
}];
export { render, staticRenderFns };