import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const autobotVuex = createNamespacedHelpers("autobot");
import formatter from "@utils/formatter";
export default {
  name: "pageBoost",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...autobotVuex.mapState(["botList"]),
    getLevelPrice() {
      return formatter.getLevelPrice;
    },
    toPre() {
      return formatter.toPre;
    }
  },
  data() {
    return {
      popType: "",
      popEnergyShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  mounted() {
    this.setWowAnim();
    console.log(this.setting);
    // this.setCoinAnim();
    if (this.botList.length == 0) {
      if (this.setting.autoBotSetting && this.setting.autoBotSetting.length > 0) {
        this.setBotList(this.setting.autoBotSetting);
      } else {
        this.getBotList();
      }
    }
  },
  methods: {
    ...autobotVuex.mapMutations(["setBotList"]),
    ...autobotVuex.mapActions(["getBotList"]),
    onBoo(type) {
      this.popType = type;
      this.popEnergyShow = true;
    },
    setCoinAnim() {
      this.$nextTick(() => {
        setTimeout(() => {
          const fn = () => {
            const headCoinRef = this.$refs.headCoinRef;
            const headStarRef = this.$refs.headStarRef;
            headCoinRef.style.animation = "none";
            headStarRef.style.animation = "none";
            if (!headStarRef && !headCoinRef) return;
            console.log("headCoinRef", headCoinRef, headStarRef);
            headCoinRef.style.animation = `bootsCoinAnim 0.2s ease-in-out infinite`;
            headCoinRef.style["animation-iteration-count"] = 2;
            const star1 = headStarRef.children[0];
            const star2 = headStarRef.children[1];
            const star3 = headStarRef.children[2];
            star1.style.animation = `bootsStar1 0.8s linear infinite`;
            star1.style["animation-iteration-count"] = 6;
            star2.style.animation = `bootsStar2 0.8s linear infinite`;
            star2.style["animation-iteration-count"] = 6;
            star3.style.animation = `bootsStar1 0.8s linear infinite`;
            star3.style["animation-iteration-count"] = 6;
          };
          fn();
          setTimeout(() => {
            fn();
          }, 5000);
        }, 1000);
      });
    }
  }
};