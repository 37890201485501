import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import { getLevelName } from "@utils/index";
import vailcode from "@utils/errcode";
export const rankMixin = {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    getLevelName() {
      return getLevelName;
    },
    rankList() {
      return this.list[this.selectTab];
    },
  },
  data() {
    const tab = [
      {
        label: this.$lang("Miners"),
        value: 0,
      },
      {
        label: this.$lang("Squad"),
        value: 1,
      },
    ];
    return {
      selectTab: 0,
      tab,
      level: 1,
      isMinLevel: false,
      isMaxLevel: false,
      list: {
        0: {},
        1: {},
      },
      popSquadShow: false,
      selectSquadId: 0,
      isLevelAnimShow: false,
      isLoading: false
    };
  },
  async mounted() {
    if (this.level >= loadConfig.level.length - 1) {
      this.level = loadConfig.level.length - 1;
      this.isMaxLevel = true;
    }
    if (this.level <= 0) {
      this.isMinLevel = true;
    }
    if (this.selectTab == 0) {
      this.level = this.userData.user_level || 0;
    } else {
      this.level = this.userData.user_group_level || 0;
    }
    this.getData();
    this.setWowAnim();
  },
  methods: {
    onDetail(item) {
      if (this.selectTab == 0) return;
      this.selectSquadId = item.id;
      this.popSquadShow = true;
    },
    onTab(item) {
      this.selectTab = item.value;
      this.getData();
    },
    async subLevel() {
      this.isLevelAnimShow = false;
      if (this.level - 1 <= 0) {
        this.level = 0;
        this.isMinLevel = true;
      } else {
        this.level--;
        this.isMinLevel = false;
      }
      if (this.level >= loadConfig.level.length - 1) {
        this.isMaxLevel = true;
      } else {
        this.isMaxLevel = false;
      }
     this.getData();
      this.isLevelAnimShow = true;
      this.setLevelAnim();
    },
    async plusLevel() {
      this.isLevelAnimShow = false;
      if (this.level + 1 >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        this.isMaxLevel = true;
      } else {
        this.level++;
      }
      if (this.level <= 0) {
        this.isMinLevel = true;
      } else {
        this.isMinLevel = false;
      }
      this.getData();
      this.isLevelAnimShow = true;
      this.setLevelAnim();
    },
    getData() {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true
        if (!this.list[this.selectTab] || !this.list[this.selectTab][this.level] || this.list[this.selectTab][this.level].length == 0) {
          let postUri = "/ranking/personal";
          if (this.selectTab == 1) {
            postUri = "/ranking/group";
          }
          const rs = await this.$http.post(postUri, { level: this.level });
          vailcode(rs, () => {
            this.isLoading = false
            //this.list[this.selectTab][this.level] = rs.data;
            this.$set(this.list[this.selectTab], this.level, rs.data);
            this.isLevelAnimShow = true
            resolve();
          });
        }else {
          resolve()
          this.isLoading = false
        }
      });
    },
    setLevelAnim() {
      this.$nextTick(() => {
        try{
          const levelRef = this.$refs.levelRef;
          if (!levelRef) return;
          console.log("levelReflevelRef", levelRef);
          const img1 = levelRef.children[0];
          const img2 = levelRef.children[1];
          img1.style.animation = `rankLevelAnim 0.2s ease-out infinite`;
          img1.style["animation-iteration-count"] = 1;
          setTimeout(() => {
            img2.style.animation = `rankLevel1Anim 0.3s ease-out infinite`;
            img2.style["animation-iteration-count"] = 1;
          }, 180);
        }catch(err) {}
      });
    },
  },
};
