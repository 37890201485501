import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const rankingVuex = createNamespacedHelpers("ranking");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "frens",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData", "extraInvite"]),
    ...rankingVuex.mapState(["firendList"])
  },
  data() {
    return {
      loadConfig,
      popShareShow: false,
      popExtraInviteShow: false,
      extraData: null
    };
  },
  mounted() {
    if (this.extraInvite.length == 0) this.getExtraInvite();
    if (this.firendList.length == 0) this.getFirendList();
  },
  beforeRouteLeave(to, from, next) {
    this.popShareShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...userVuex.mapActions(["getExtraInvite"]),
    ...rankingVuex.mapActions(["getFirendList"]),
    onBtnSend() {
      //this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}?start=referral`);
      this.popShareShow = true;
    },
    onExtra(item) {
      if (!item.has_claim) {
        this.extraData = item;
        this.popExtraInviteShow = true;
      }
    }
  }
};