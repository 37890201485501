import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const squadVuex = createNamespacedHelpers("squad");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import { getLevelName } from "@utils/index";
export default {
  name: "pageSquad",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    groupId() {
      return this.$route.query.id;
    },
    getLevelName() {
      return getLevelName;
    }
  },
  data() {
    const mTab = [{
      label: this.$lang("Day"),
      value: 0
    }, {
      label: this.$lang("Week"),
      value: 1
    }];
    return {
      mTab,
      selectMTab: 0,
      detail: {},
      level: 0,
      rankList: [],
      popPromptShow: false,
      isFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popPromptShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    groupId: {
      async handler(id) {
        console.log("id======", id);
        if (id > 0) {
          // this.setLevel();
          // this.detail = await this.squadDetail(id);
          // this.rankList = await this.squadRank(this.detail.id);
          // console.log("this.detail", this.rankList);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...squadVuex.mapActions(["squadDetail", "squadRank", "leaveSquad"]),
    onSkip() {
      this.WebApp.openTelegramLink(`https://t.me/${this.detail.group_id}`);
    },
    async leaveSquadFn() {
      this.popPromptShow = true;
    },
    onCancel() {
      this.popPromptShow = false;
    },
    async onConfirm() {
      try {
        if (this.isFlag) {
          this.isFlag = false;
          await this.leaveSquad(this.detail.id);
          this.popPromptShow = false;
          this.$toasted.success(this.$lang("Leave successfully"));
          let timer = setTimeout(() => {
            clearTimeout(timer);
            timer = null;
            this.isFlag = true;
            this.$router.push("/");
          }, 2000);
        }
      } catch (err) {
        this.isFlag = true;
      }
    },
    setLevel() {
      if (this.level >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        this.isMaxLevel = true;
      }
      if (this.level <= 0) {
        this.isMinLevel = true;
      }
    },
    onMTab(item) {
      this.selectMTab = item.value;
    },
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=%F0%9F%8E%81%20+25k%20${this.setting.coin}%20as%20a%20first-time%20gift%0A%F0%9F%8E%81%F0%9F%8E%81%F0%9F%8E%81%20+50k%20${this.setting.coin}%20if%20you%20have%20Telegram%20Premium%20&url=https://t.me/${this.setting.botname}/${this.setting.app}?startapp=ref_${this.userData.ref_code}-gid_${this.detail.id}`);
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.setWowAnim();
      this.setLevel();
      this.detail = await this.squadDetail(this.$route.query.id);
      this.rankList = await this.squadRank(this.detail.id);
    }
  }
};