import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import { getLevelName } from "@utils/index";
import vailcode from "@utils/errcode";
export default {
  name: "rank",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    getLevelName() {
      return getLevelName;
    }
  },
  data() {
    const tab = [{
      label: this.$lang("Miners"),
      value: 0
    }, {
      label: this.$lang("Squad"),
      value: 1
    }];
    // const mTab = [
    //   {
    //     label: this.$lang("Day"),
    //     value: 0,
    //   },
    //   {
    //     label: this.$lang("Week"),
    //     value: 1,
    //   },
    // ];
    return {
      selectTab: 0,
      tab,
      level: 1,
      isMinLevel: false,
      isMaxLevel: false,
      list: {
        0: {},
        1: {}
      },
      popSquadShow: false,
      selectSquadId: 0
    };
  },
  mounted() {
    if (this.theme === "rocket") {
      return;
    }
    if (this.level >= loadConfig.level.length - 1) {
      this.level = loadConfig.level.length - 1;
      this.isMaxLevel = true;
    }
    if (this.level <= 0) {
      this.isMinLevel = true;
    }
    if (this.selectTab == 0) {
      this.level = this.userData.user_level || 0;
    } else {
      this.level = this.userData.user_group_level || 0;
    }
    this.getData();
  },
  methods: {
    onDetail(item) {
      if (this.selectTab == 0) return;
      this.selectSquadId = item.id;
      this.popSquadShow = true;
    },
    onTab(item) {
      this.selectTab = item.value;
      this.getData();
    },
    subLevel() {
      if (this.level - 1 <= 0) {
        this.level = 0;
        this.isMinLevel = true;
      } else {
        this.level--;
        this.isMinLevel = false;
      }
      if (this.level >= loadConfig.level.length - 1) {
        this.isMaxLevel = true;
      } else {
        this.isMaxLevel = false;
      }
      this.getData();
    },
    plusLevel() {
      if (this.level + 1 >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        this.isMaxLevel = true;
      } else {
        this.level++;
      }
      if (this.level <= 0) {
        this.isMinLevel = true;
      } else {
        this.isMinLevel = false;
      }
      this.getData();
    },
    async getData() {
      if (!this.list[this.selectTab] || !this.list[this.selectTab][this.level] || this.list[this.selectTab][this.level].length == 0) {
        let postUri = "/ranking/personal";
        if (this.selectTab == 1) {
          postUri = "/ranking/group";
        }
        const rs = await this.$http.post(postUri, {
          level: this.level
        });
        vailcode(rs, () => {
          //this.list[this.selectTab][this.level] = rs.data;
          this.$set(this.list[this.selectTab], this.level, rs.data);
        });
      }
    }
  }
};