var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pageRank"
  }, [_c('div', {
    staticClass: "pageRank-main"
  }, [_c('div', {
    staticClass: "rank-switch",
    style: {
      background: `url(${require(`@images/${_vm.theme}/rank-bg.jpg`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "rank-switch-main"
  }, [_c('div', {
    staticClass: "rank-switch-head"
  }, [_c('div', {
    staticClass: "switch-arrow left",
    class: _vm.isMinLevel && 'disable',
    on: {
      "click": function ($event) {
        return _vm.subLevel();
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _c('div', {
    staticClass: "switch-main"
  }, [_vm.isLevelAnimShow ? _c('div', {
    ref: "levelRef",
    staticClass: "switch-item wow bounceIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm.isLevelAnimShow ? [_c('div', {
    staticClass: "img a",
    style: {
      background: `url(${require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.level)}.png`)}) center/auto 100%`
    }
  }), _c('div', {
    staticClass: "img b",
    style: {
      background: `url(${require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.level)}.png`)}) center/auto 100%`
    }
  })] : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "switch-arrow right",
    class: _vm.isMaxLevel && 'disable',
    on: {
      "click": function ($event) {
        return _vm.plusLevel();
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]), _c('div', {
    staticClass: "rank-switch-progress"
  }, [_c('div', {
    staticClass: "progress-title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("getLevelName")(_vm.level)))]), _c('i', [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.userData.user_level >= _vm.level ? _vm.userData.coin : "0")) + " / " + _vm._s(_vm._f("nFormatter")(_vm.setting.userLevelCoin[_vm.level + 1] ? _vm.setting.userLevelCoin[_vm.level + 1] : _vm.setting.userLevelCoin[_vm.level])))])]), _c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: `${(_vm.userData.user_level >= _vm.level ? _vm.userData.coin : 0) / (_vm.setting.userLevelCoin[_vm.level + 1] ? _vm.setting.userLevelCoin[_vm.level + 1] : _vm.setting.userLevelCoin[_vm.level]) * 100}%`
    }
  })])])])]), _c('div', {
    staticClass: "mTab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTab-item",
      class: item.value == _vm.selectTab && 'active',
      on: {
        "click": function ($event) {
          !_vm.isMobile && _vm.onTab(item);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _vm.isLoading ? [_c('div', {
    staticClass: "list-loading"
  }, [_c('div', {
    staticClass: "sLoading"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0)])] : [_vm.rankList && _vm.rankList[_vm.level] && _vm.rankList[_vm.level].length > 0 ? [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.list[_vm.selectTab][_vm.level].length > 0 ? _vm._l(_vm.rankList[_vm.level], function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "item",
      attrs: {
        "data-index": index
      },
      on: {
        "click": function ($event) {
          return _vm.onDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _vm.selectTab == 1 ? [_c('div', {
      staticClass: "item-info"
    }, [_c('m-head', {
      attrs: {
        "src": item.group_icon,
        "size": "26"
      }
    }), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.group_name))])], 1), _c('div', {
      staticClass: "item-price"
    }, [_c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.group_coin, 3, true)) + " ")]), _c('div', {
      staticClass: "op"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)])] : [_c('div', {
      staticClass: "item-info"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "size": "26",
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))])], 1), _c('div', {
      staticClass: "item-price"
    }, [_c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.coin)) + " ")]), item.id == _vm.userData.id ? _c('div', {
      staticClass: "op you"
    }, [_vm._v("You")]) : _vm._e()])]], 2);
  }) : _vm._e()], 2)] : _vm._e()]], 2), _c('pop-squad', {
    attrs: {
      "show": _vm.popSquadShow,
      "id": _vm.selectSquadId
    },
    on: {
      "update:show": function ($event) {
        _vm.popSquadShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };