var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pageEran"
  }, [_c('div', {
    staticClass: "pageEarn-main"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "total-main"
  }, [_c('div', {
    staticClass: "total__num wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    staticClass: "key",
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))])]), _c('div', {
    staticClass: "total__label wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Earn more coins")))])])]), _c('div', {
    staticClass: "daily"
  }, [_c('div', {
    staticClass: "daily-main"
  }, [_c('div', {
    staticClass: "daily-icon wow zoomIn",
    style: {
      background: `url(${require(`@images/${_vm.theme}/earn-img-daily-reward.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "daily-info"
  }, [_c('div', {
    staticClass: "daily__title"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Reward")))]), _c('div', {
    staticClass: "daily__label"
  }, [_vm._v(_vm._s(_vm.$lang("Hurry up and get it")))])]), _c('div', {
    staticClass: "daily-btns"
  }, [_c('div', {
    staticClass: "daily-btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('sign');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Claim")) + " "), _c('div', {
    staticClass: "tag"
  })])])])]), _c('div', {
    staticClass: "task"
  }, [_c('div', {
    staticClass: "list-main"
  }, [_c('div', {
    staticClass: "list__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Official")) + " "), _vm.taskNum > 0 ? _c('em', [_vm._v("(" + _vm._s(_vm.taskNum) + ")")]) : _vm._e()]), _vm.setting.socials.discord ? _c('div', {
    staticClass: "item",
    class: _vm.userData.joined_discord == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('discord', _vm.setting.socials.discord);
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-discord"
    }
  })], 1), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-info__label"
  }, [_vm._v(_vm._s(_vm.$lang("Join discrod")))]), _c('div', {
    staticClass: "item-info-reward"
  }, [_c('div', {
    staticClass: "item-info-reward-item"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.discord)) + " ")])])]), _c('div', {
    staticClass: "item-arrow"
  }, [_vm.userData.joined_discord == 0 ? _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  }) : _vm.userData.joined_discord == 1 ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm.setting.socials.channel ? _c('div', {
    staticClass: "item",
    class: _vm.userData.joined_channel == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('channel', _vm.setting.socials.channel);
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-info__label"
  }, [_vm._v(_vm._s(_vm.$lang("Join channel")))]), _c('div', {
    staticClass: "item-info-reward"
  }, [_c('div', {
    staticClass: "item-info-reward-item"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.channel)) + " ")])])]), _c('div', {
    staticClass: "item-arrow"
  }, [_vm.userData.joined_channel == 0 ? _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  }) : _vm.userData.joined_channel == 1 ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm.setting.socials.twitter ? _c('div', {
    staticClass: "item",
    class: _vm.userData.joined_twitter == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('twitter', _vm.setting.socials.twitter);
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-info__label"
  }, [_vm._v(_vm._s(_vm.$lang("Follow us on X")))]), _c('div', {
    staticClass: "item-info-reward"
  }, [_c('div', {
    staticClass: "item-info-reward-item"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.twitter)) + " ")])])]), _c('div', {
    staticClass: "item-arrow"
  }, [_vm.userData.joined_twitter == 0 ? _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  }) : _vm.userData.joined_twitter == 1 ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm.setting.socials.telegram ? _c('div', {
    staticClass: "item",
    class: _vm.userData.joined_telegram == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('telegram', _vm.setting.socials.telegram);
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-info__label"
  }, [_vm._v(_vm._s(_vm.$lang("Join group")))]), _c('div', {
    staticClass: "item-info-reward"
  }, [_c('div', {
    staticClass: "item-info-reward-item"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.telegram)) + " ")])])]), _c('div', {
    staticClass: "item-arrow"
  }, [_vm.userData.joined_telegram == 0 ? _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  }) : _vm.userData.joined_telegram == 1 ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm.setting.reward.bindWallet || _vm.setting.reward.bindWalletStock ? _c('div', {
    staticClass: "item",
    class: _vm.userData.bind_wallet && 'active',
    on: {
      "click": _vm.onBindWallet
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-info__label"
  }, [_vm._v(_vm._s(_vm.$lang("Connect wallet")))]), _c('div', {
    staticClass: "item-info-reward"
  }, [_c('div', {
    staticClass: "item-info-reward-item"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.bindWallet)) + " ")])])]), _c('div', {
    staticClass: "item-arrow"
  }, [!_vm.userData.bind_wallet ? _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  }) : _vm.userData.bind_wallet ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm._l(_vm.taskList, function (item, index) {
    return item.is_official == 1 ? _c('div', {
      key: index,
      staticClass: "item",
      class: item.completed == 1 && 'active',
      on: {
        "click": function ($event) {
          return _vm.onExtralJoin(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-icon wow zoomIn",
      attrs: {
        "data-wow-duration": "0.5s"
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "onerror": "this.classList.add('error');"
      }
    }) : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item-info__label"
    }, [_vm._v(_vm._s(item.title))]), item.reward > 0 || item.reward_stock > 0 ? _c('div', {
      staticClass: "item-info-reward"
    }, [item.reward > 0 ? _c('div', {
      staticClass: "item-info-reward-item"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")]) : _vm._e()]) : _vm._e()]), _c('div', {
      staticClass: "item-arrow",
      class: item.completed == -1 && 'refresh'
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : item.completed == -1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-refresh"
      }
    })] : _vm._e()], 2)]) : _vm._e();
  }), _vm._l(_vm.advList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: item.completed == 1 && 'active',
      on: {
        "click": function ($event) {
          item.completed == 0 && _vm.onExtralJoin(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-icon wow zoomIn",
      attrs: {
        "data-wow-duration": "0.5s"
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "onerror": "this.classList.add('error');"
      }
    }) : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item-info__label"
    }, [_vm._v(_vm._s(item.title))]), item.reward > 0 || item.reward_stock > 0 ? _c('div', {
      staticClass: "item-info-reward"
    }, [item.reward > 0 ? _c('div', {
      staticClass: "item-info-reward-item"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")]) : _vm._e(), item.reward_stock > 0 ? _c('div', {
      staticClass: "item-info-reward-item key"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(item.reward_stock) + " ")]) : _vm._e()]) : _vm._e()]), item.daily_limit_count > 0 ? _c('div', {
      staticClass: "item-com-count"
    }, [_vm._v(_vm._s(item.daily_completion_count) + "/" + _vm._s(item.daily_limit_count))]) : _vm._e(), item.countdown > 0 ? _c('div', {
      staticClass: "item-time"
    }, [_vm._v(" " + _vm._s(_vm._f("formatHour")(item.countdown)) + " ")]) : _vm._e(), _c('div', {
      staticClass: "item-arrow",
      class: item.completed == -1 && 'refresh'
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : item.completed == -1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-refresh"
      }
    })] : _vm._e()], 2)]);
  })], 2), _c('div', {
    staticClass: "list-main"
  }, [_c('div', {
    staticClass: "list__title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Extra Tasks")) + " "), _c('div', {
    staticClass: "tag"
  })]), _vm.extraTaskNum > 0 ? _c('em', [_vm._v("(" + _vm._s(_vm.extraTaskNum) + ")")]) : _vm._e()]), _vm._l(_vm.taskList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "item-icon wow zoomIn",
      attrs: {
        "data-wow-duration": "0.5s"
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "onerror": "this.classList.add('error');"
      }
    }) : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item-info__label"
    }, [_vm._v(_vm._s(item.title))]), item.reward > 0 || item.reward_stock > 0 ? _c('div', {
      staticClass: "item-info-reward"
    }, [item.reward > 0 ? _c('div', {
      staticClass: "item-info-reward-item"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")]) : _vm._e(), item.reward_stock > 0 ? _c('div', {
      staticClass: "item-info-reward-item key"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(item.reward_stock) + " ")]) : _vm._e()]) : _vm._e()]), _c('div', {
      staticClass: "item-arrow",
      class: item.completed == -1 && 'refresh'
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : item.completed == -1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-refresh"
      }
    })] : _vm._e()], 2)]);
  })], 2)])]), _c('pop-daily', {
    attrs: {
      "show": _vm.popDailyShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popDailyShow = $event;
      }
    }
  }), _c('pop-up-gold', {
    attrs: {
      "show": _vm.popUpGoldShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpGoldShow = $event;
      }
    }
  }), _c('pop-coin-hero', {
    attrs: {
      "show": _vm.popCoinHeroShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popCoinHeroShow = $event;
      }
    }
  }), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  }), _c('pop-earn-tip', {
    attrs: {
      "show": _vm.popEarnTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEarnTipShow = $event;
      }
    }
  }), _c('pop-reward-result', {
    attrs: {
      "show": _vm.isPopRewardResultShow,
      "select-row": _vm.selectRow,
      "showBtn": false,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopRewardResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };